.dd-container{
    background: #f5f5f5;
    display:flex;
    flex-wrap:wrap;
    min-height: 70vh;
    /*justify-content: center;*/
}
.dd-panel{
    min-height:400px;
    /*width:30%;*/
    margin:3%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.smooth-dnd-draggable-wrapper div{
    text-align:center;
    /*cursor:all-scroll;*/
    /*box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);*/
}
.smooth-dnd-container{
    overflow:auto;
    /*padding-bottom:60px;
    max-height:350px;*/
}

.user-ghost {
    transition: transform 0.18s ease;
    transform: rotateZ(5deg); 
}

.user-ghost-drop{
    transition: transform 0.18s ease-in-out;
    transform: rotateZ(0deg);
}

@media screen and (max-width: 992px) {
    .dd-panel{
        width:85%;
        margin-bottom:50px;
    }
    .dd-bigPanel {
        width: 85%;
        margin-bottom: 50px;
    }
}

.app-select {
    background-color: #f5f5f5;
    padding-left: 9%;
}

.app-select .k-select {
    background-color: #007bff;
    color: white;
}

.app-select .k-dropdown .k-dropdown-wrap {
    border-color: #007bff;
}

.dropdown-roleButton {
    float: right;
}

.roleButton {
    background-color: limegreen !important;
    border-color: black !important;
}