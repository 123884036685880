.form-reset-full {
    display: grid;
    align-content: center;
    height: 100vh;
}

.form-reset-container {
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    background-color: dimgray;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
}

@media screen and (max-width: 992px) {
    .form-reset-container {
        width: 90%;
    }
}

#pwdResetContainer .k-textbox {
    border: 2px solid #fff;
    border-right: 1px solid #ddd;
}

#pwdResetContainer .k-textbox.k-state-invalid {
    border: 1px solid #f31700;
}