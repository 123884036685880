.menu-item,
.menu-open-button {
   background: #EEEEEE;
   border-radius: 100%;
   width: 50px;
   height: 50px;
   right: 80px;
   top:100px;
   position: absolute;
   color: #FFFFFF;
   text-align: center;
   line-height: 80px;
   -webkit-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
   -webkit-transition: -webkit-transform ease-out 200ms;
   transition: -webkit-transform ease-out 200ms;
   transition: transform ease-out 200ms;
   transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
}

.menu-item {
   z-index: 10;
}

.menu-open-button {
   z-index: 15;
}

.menu-open {
   display: none;
}

.lines {
   width: 25px;
   height: 3px;
   background: #596778;
   display: block;
   position: absolute;
   top: 50%;
   left: 50%;
   margin-left: -12.5px;
   margin-top: -1.5px;
   -webkit-transition: -webkit-transform 200ms;
   transition: -webkit-transform 200ms;
   transition: transform 200ms;
   transition: transform 200ms, -webkit-transform 200ms;
}

.line-1 {
   -webkit-transform: translate3d(0, -8px, 0);
   transform: translate3d(0, -8px, 0);
}

.line-2 {
   -webkit-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
}

.line-3 {
   -webkit-transform: translate3d(0, 8px, 0);
   transform: translate3d(0, 8px, 0);
}

.menu-open:checked + .menu-open-button .line-1 {
   -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
   transform: translate3d(0, 0, 0) rotate(45deg);
}

.menu-open:checked + .menu-open-button .line-2 {
   -webkit-transform: translate3d(0, 0, 0) scale(0.1, 1);
   transform: translate3d(0, 0, 0) scale(0.1, 1);
}

.menu-open:checked + .menu-open-button .line-3 {
   -webkit-transform: translate3d(0, 0, 0) rotate(-45deg);
   transform: translate3d(0, 0, 0) rotate(-45deg);
}

.menu {
   margin: auto;
   position: absolute;
   top: 20;
   right: 30;
   text-align: center;
   box-sizing: border-box;
   font-size: 26px;
}


/* .menu-item {
   transition: all 0.1s ease 0s;
} */

.menu-item:hover {
   background: #EEEEEE;
   color: #3290B1;
}

.menu-item:nth-child(3) {
   -webkit-transition-duration: 180ms;
   transition-duration: 180ms;
}

.menu-item:nth-child(4) {
   -webkit-transition-duration: 180ms;
   transition-duration: 180ms;
}

.menu-item:nth-child(5) {
   -webkit-transition-duration: 180ms;
   transition-duration: 180ms;
}

.menu-item:nth-child(6) {
   -webkit-transition-duration: 180ms;
   transition-duration: 180ms;
}

.menu-item:nth-child(7) {
   -webkit-transition-duration: 180ms;
   transition-duration: 180ms;
}

.menu-item:nth-child(8) {
   -webkit-transition-duration: 180ms;
   transition-duration: 180ms;
}

.menu-item:nth-child(9) {
   -webkit-transition-duration: 180ms;
   transition-duration: 180ms;
}

.menu-open-button {
   z-index: 12;
   -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
   transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
   -webkit-transition-duration: 400ms;
   transition-duration: 400ms;
   -webkit-transform: scale(1.1, 1.1) translate3d(0, 0, 0);
   transform: scale(1.1, 1.1) translate3d(0, 0, 0);
   cursor: pointer;
   box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
}

.menu-open-button {
   -webkit-transform: scale(1.2, 1.2) translate3d(0, 0, 0);
   transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}

.menu-open:checked + .menu-open-button {
   -webkit-transition-timing-function: linear;
   transition-timing-function: linear;
   -webkit-transition-duration: 200ms;
   transition-duration: 200ms;
   -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
   transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.menu-open:checked ~ .menu-item {
   -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
   transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
}

.menu-open:checked ~ .menu-item:nth-child(3) {
   transition-duration: 180ms;
   -webkit-transition-duration: 180ms;
   -webkit-transform: translate3d(0.08361px, -104.99997px, 0);
   transform: translate3d(0.08341px, -72.99997px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(4) {
   transition-duration: 280ms;
   -webkit-transition-duration: 280ms;
   -webkit-transform: translate3d(90.9466px, -52.47586px, 0);
   transform: translate3d(70.9466px, -32.47586px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(5) {
   transition-duration: 380ms;
   -webkit-transition-duration: 380ms;
   -webkit-transform: translate3d(90.9466px, 52.47586px, 0);
   transform: translate3d(70.9466px, 32.47586px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(6) {
   transition-duration: 480ms;
   -webkit-transition-duration: 480ms;
   -webkit-transform: translate3d(0.08361px, 104.99997px, 0);
   transform: translate3d(0.08341px, 72.99997px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(7) {
   transition-duration: 580ms;
   -webkit-transition-duration: 580ms;
   -webkit-transform: translate3d(-90.86291px, 52.62064px, 0);
   transform: translate3d(-70.86291px, 32.62064px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(8) {
   transition-duration: 680ms;
   -webkit-transition-duration: 680ms;
   -webkit-transform: translate3d(-91.03006px, -52.33095px, 0);
   transform: translate3d(-71.03006px, -32.33095px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(9) {
   transition-duration: 780ms;
   -webkit-transition-duration: 780ms;
   -webkit-transform: translate3d(-0.25084px, -104.9997px, 0);
   transform: translate3d(-0.25064px, -104.9977px, 0);
}

.blue {
   background-color: #669AE1;
   box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
   text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}
.blue:hover {
   color: #669AE1;
   text-shadow: none;
}

.green {
   background-color: #70CC72;
   box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
   text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.green:hover {
   color: #70CC72;
   text-shadow: none;
}

.red {
   background-color: #FE4365;
   box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
   text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.red:hover {
   color: #FE4365;
   text-shadow: none;
}

.purple {
   background-color: #C49CDE;
   box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
   text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.purple:hover {
   color: #C49CDE;
   text-shadow: none;
}

.orange {
   background-color: #FC913A;
   box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
   text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.orange:hover {
   color: #FC913A;
   text-shadow: none;
}

.lightblue {
   background-color: #62C2E4;
   box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
   text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.lightblue:hover {
   color: #62C2E4;
   text-shadow: none;
}
.menu-containter{
    height:250px;
    background: #f5f5f5;
}
.button-menu-container{
    text-align:right;
}

.menu-item > svg {
    font-size: 24px;
    vertical-align: super;
}

@media screen and (max-width: 992px) {
   .menu-item,
   .menu-open-button{
      right:calc(50% - 30px) !important;
   }
   .div-clock{
      display:none;
   }
   .overlay-clock{
      display:none;
   }
 }
 .div-clock{
   position:absolute;
   width:500px;
   text-align:left;
   top:40px;
   margin-left:4%;
 }
 .overlay-clock{
   position:absolute;
   width:500px;
   height:150px;
   text-align:left;
   top:40px;
   margin-left:4%;
   z-index:10000;
   background-color:rgba(255, 0, 0, 0);
 }
 .menu-trama{
   opacity:0.3;
   background-image:url('/img/texture.png');
   background-size: cover;
   position:fixed;
   bottom:0;
   left:0;
   top:0;
   width:100%;
 }
.full-container {
    padding: 0;
    margin: 0;
    height: 100vh;
    background: -webkit-linear-gradient(#fff 0%, #f2f7ff 100%);
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
}

@media screen and (max-height: 530px) {
    body {
        overflow: auto;
    }
}

.active-back {
    color: #919cb2 !important;
}
.active-back:hover {
    cursor: pointer;
    color: #286efa !important;
}
.back {
    position: absolute;
    height: 20px;
    width: 40px;
    color: #d6dae4;
    display: block;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin-left: 20px;
    margin-top: -30px;
    font-size: 24px;
}
.shift {
    left: -330px !important;
}
.credential-title {
    position: absolute;
    margin-left: 100px;
    font-size: 22px;
    font-weight: 200;
    color: #4f5c76;
}
.login-input {
    width: 250px;
    height: 35px;
    border-radius: 3px;
    border: 1px solid #ccc;
    font-size: 14px;
    padding-left: 10px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin-right: calc(100% - 250px);
    margin-bottom: 5px;
}
.login-input::-webkit-input-placeholder {
    font-size: 14px;
}
.login-input:focus {
    border: 1px solid #286efa !important;
    outline-width: 0;
}
.login-button {
    margin-top: 25px;
    width: 262px;
    height: 40px;
    background: #286efa;
    color: #fff;
    font-size: 14px;
    border: 1px solid #286efa;
    border-radius: 3px;
}
.login-button:hover {
    background: #3c82ff;
    cursor: pointer;
}
.form-container {
    position: relative;
    margin: 0 auto;
    margin-top: 100px;
    width: 400px;
    height: 400px;
    background: #fff;
    box-shadow: 0px 5px 80px 0px #e4e8f0;
    border-radius: 5px;
    overflow: hidden;
}
.form-container .login-form {
    margin-top: 50px;
    width: 750px;
}
.form-container .login-form .inputs {
    position: relative;
    left: 65px;
    top: 80px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.form-container .login-form .inputs .email {
    float: left;
    position: relative;
    width: 350px;
}
.form-container .login-form .inputs .password {
    float: right;
    position: relative;
    width: 350px;
}
.warning {
    color: #f00;
    text-align: center;
    font-size: 15px;
    margin-top: 30px;
}
.login-loader {
    margin-left: 42.5%;
    margin-top: 40%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #e8ebf1;
    border-top-color: #286efa;
    -webkit-animation: spinner 1s infinite linear;
            animation: spinner 1s infinite linear;
}
@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.login-logo {
    width: 70px;
    margin-top: 10px;
    margin-left: 165px;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
    outline: none;
    color: blue;
}

.link-button:hover {
    text-decoration: none;
}
  
.container-cards{
    justify-content: center;
    width:100%;
    display:flex;
    flex-wrap:wrap;
  }
  .flip-card {
  background-color: transparent;
  width: 250px;
  margin: 2%;
  height: 150px;
  -webkit-perspective: 1000px;
          perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    cursor:pointer;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-transition: -webkit-transform 0.8s;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 3px;
  }
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  }
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  -webkit-transform: translate3d(0,0,0);
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius:3px;
  }
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
  background-color: #ffffff;
  color: black;
  }
  /* Style the back side */
  .flip-card-back {
    background-color: dodgerblue;
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }

  .back-desc{
    font-size:12px;
    padding-left:5px;
    padding-right:5px;
    margin-top:-10px;
  }

  .icon-card{
    width:25%;
    position: absolute;
    left: 50%;
    top: 42%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .section-dash{
    box-shadow:none;
    padding-bottom:10vh;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f5f5f5), to(#000000));
    background-image: -webkit-linear-gradient(#f5f5f5, #000000);
    background-image: linear-gradient(#f5f5f5, #000000);
    padding-bottom:5vh;
    min-height:69vh;
  }
  .section-logo-background{
    width:25%;
    max-width:475px;
    position:fixed;
    bottom:5vh;
    left:0;
    opacity:0.1;
  }
  body{

    margin:0;
    padding:0;
  }
  .footer-dash{
    height:5vh;
    background-color:#f5f5f5;
    width:100%;
    position:fixed;
    bottom:0;
    border-top:1px solid #ccc;
  }

  .credits{
    font-size:12px;
    margin-top: 12px;
    color:#707070;
    text-align:center;
  }

  @media screen and (max-width: 992px) {
    .section-dash {
      padding-top:2vh;
      box-shadow:none;
      padding-bottom:10vh;
    }
  }
  @media screen and (max-width: 600px) {
    .flip-card{
      width:85%;
    }
    .section-logo-background{
      width:180px;
      position:fixed;
      left: 52%;
      top: 34.9%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      opacity:0.5;
      -webkit-transition:0.2s;
      transition:0.2s;
    }
  }

  .front-title{
    width:100%;
    background-color: dodgerblue;
    border-top:1px solid #ccc;
    padding-top:7px;
    padding-bottom:7px;
    text-align:center;
    bottom:0;
    color:#fff;
    position:absolute;
    border-radius:0 0 3px 3px;
  }

  .back-arrow{
    font-size:20px;
    padding-top:10px;
    padding-bottom:10px;
    text-align:center;
    bottom:0;
    color:#fff;
    position:absolute;
    -webkit-transition:0.8s;
    transition:0.8s;
  }

  .back-arrow:hover{
    color:#000;
    -webkit-transition:0.8s;
    transition:0.8s;
  }
.dd-container{
    background: #f5f5f5;
    display:flex;
    flex-wrap:wrap;
    min-height: 70vh;
    /*justify-content: center;*/
}
.dd-panel{
    min-height:400px;
    /*width:30%;*/
    margin:3%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.smooth-dnd-draggable-wrapper div{
    text-align:center;
    /*cursor:all-scroll;*/
    /*box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);*/
}
.smooth-dnd-container{
    overflow:auto;
    /*padding-bottom:60px;
    max-height:350px;*/
}

.user-ghost {
    -webkit-transition: -webkit-transform 0.18s ease;
    transition: -webkit-transform 0.18s ease;
    transition: transform 0.18s ease;
    transition: transform 0.18s ease, -webkit-transform 0.18s ease;
    -webkit-transform: rotateZ(5deg);
            transform: rotateZ(5deg); 
}

.user-ghost-drop{
    -webkit-transition: -webkit-transform 0.18s ease-in-out;
    transition: -webkit-transform 0.18s ease-in-out;
    transition: transform 0.18s ease-in-out;
    transition: transform 0.18s ease-in-out, -webkit-transform 0.18s ease-in-out;
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
}

@media screen and (max-width: 992px) {
    .dd-panel{
        width:85%;
        margin-bottom:50px;
    }
    .dd-bigPanel {
        width: 85%;
        margin-bottom: 50px;
    }
}

.app-select {
    background-color: #f5f5f5;
    padding-left: 9%;
}

.app-select .k-select {
    background-color: #007bff;
    color: white;
}

.app-select .k-dropdown .k-dropdown-wrap {
    border-color: #007bff;
}

.dropdown-roleButton {
    float: right;
}

.roleButton {
    background-color: limegreen !important;
    border-color: black !important;
}
.k-pager-numbers, .k-state-selected{
    background:#007bff !important;
    color:#fff !important;
}
.k-state-selected:hover{
    color:#007bff !important;
    background-color:transparent !important;
}
.k-pager-numbers:hover{
    color:#007bff !important;
    background-color:transparent !important;
}
.k-grid{
    margin-bottom:2vh;
    min-height:65vh;
    margin-left:1%;
    margin-right:1%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.k-window-titlebar, .k-dialog-titlebar{
    background:#007bff !important;
    color:#fff !important;
}
.k-dialog-buttongroup .k-primary{
    color:#007bff !important;
}
.k-dialog-buttongroup .k-primary:hover{
    background:#007bff !important;
    color:#fff !important;
    border-color:#007bff !important
}

@media screen and (max-width: 992px) {
    .edit-text{
        display:none;
    }
}
.form-edit-container {
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    width: 35%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    background-color: dimgray;
}

.form-edit-confirm-container {
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    width: 35%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.form-edit-confirm-container .k-textbox {
    border-color: #007bff;
}

.form-edit-input {
    margin: 15px;
    width: calc(50% - 30px);
}

.form-edit-confirm-input {
    margin: 15px;
    width: calc(50% - 30px);
}

.form-edit-title {
    margin-left: 32.5%;
}

.form-edit-input .k-textbox {
    color: black;
}

.k-textbox.k-state-invalid {
    color: #f31700;
    border-color: #f31700;
}

.form-edit-input .k-textbox-container.k-state-empty>.k-label {
    color: #304b67;
}

.form-edit-input .k-textbox {
    border: 2px solid #fff;
    border-right: 1px solid #ddd;
}

.form-edit-input .k-textbox.k-state-invalid {
    border: 1px solid #f31700;
}

@media screen and (max-width: 992px) {
    .form-edit-container {
        width: 90%;
    }

    .form-edit-confirm-container {
        width: 90%;
    }

    .form-edit-input {
        width: 100%;
    }

    .form-edit-confirm-input {
        width: 100%;
    }

    .form-edit-title {
        margin-left: 5%;
    }
    
    .form-edit-confirm-container .k-button {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}


.form-reset-full {
    display: grid;
    align-content: center;
    height: 100vh;
}

.form-reset-container {
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    background-color: dimgray;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
}

@media screen and (max-width: 992px) {
    .form-reset-container {
        width: 90%;
    }
}

#pwdResetContainer .k-textbox {
    border: 2px solid #fff;
    border-right: 1px solid #ddd;
}

#pwdResetContainer .k-textbox.k-state-invalid {
    border: 1px solid #f31700;
}
* {
  font-family: Arial, Helvetica, sans-serif;
}
