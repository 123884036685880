.full-container {
    padding: 0;
    margin: 0;
    height: 100vh;
    background: -webkit-linear-gradient(#fff 0%, #f2f7ff 100%);
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
}

@media screen and (max-height: 530px) {
    body {
        overflow: auto;
    }
}

.active-back {
    color: #919cb2 !important;
}
.active-back:hover {
    cursor: pointer;
    color: #286efa !important;
}
.back {
    position: absolute;
    height: 20px;
    width: 40px;
    color: #d6dae4;
    display: block;
    transition: 0.3s;
    margin-left: 20px;
    margin-top: -30px;
    font-size: 24px;
}
.shift {
    left: -330px !important;
}
.credential-title {
    position: absolute;
    margin-left: 100px;
    font-size: 22px;
    font-weight: 200;
    color: #4f5c76;
}
.login-input {
    width: 250px;
    height: 35px;
    border-radius: 3px;
    border: 1px solid #ccc;
    font-size: 14px;
    padding-left: 10px;
    transition: 0.3s;
    margin-right: calc(100% - 250px);
    margin-bottom: 5px;
}
.login-input::-webkit-input-placeholder {
    font-size: 14px;
}
.login-input:focus {
    border: 1px solid #286efa !important;
    outline-width: 0;
}
.login-button {
    margin-top: 25px;
    width: 262px;
    height: 40px;
    background: #286efa;
    color: #fff;
    font-size: 14px;
    border: 1px solid #286efa;
    border-radius: 3px;
}
.login-button:hover {
    background: #3c82ff;
    cursor: pointer;
}
.form-container {
    position: relative;
    margin: 0 auto;
    margin-top: 100px;
    width: 400px;
    height: 400px;
    background: #fff;
    box-shadow: 0px 5px 80px 0px #e4e8f0;
    border-radius: 5px;
    overflow: hidden;
}
.form-container .login-form {
    margin-top: 50px;
    width: 750px;
}
.form-container .login-form .inputs {
    position: relative;
    left: 65px;
    top: 80px;
    transition: 0.3s;
}
.form-container .login-form .inputs .email {
    float: left;
    position: relative;
    width: 350px;
}
.form-container .login-form .inputs .password {
    float: right;
    position: relative;
    width: 350px;
}
.warning {
    color: #f00;
    text-align: center;
    font-size: 15px;
    margin-top: 30px;
}
.login-loader {
    margin-left: 42.5%;
    margin-top: 40%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #e8ebf1;
    border-top-color: #286efa;
    animation: spinner 1s infinite linear;
}
@-moz-keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-o-keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.login-logo {
    width: 70px;
    margin-top: 10px;
    margin-left: 165px;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
    outline: none;
    color: blue;
}

.link-button:hover {
    text-decoration: none;
}
  