.form-edit-container {
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    width: 35%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    background-color: dimgray;
}

.form-edit-confirm-container {
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    width: 35%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.form-edit-confirm-container .k-textbox {
    border-color: #007bff;
}

.form-edit-input {
    margin: 15px;
    width: calc(50% - 30px);
}

.form-edit-confirm-input {
    margin: 15px;
    width: calc(50% - 30px);
}

.form-edit-title {
    margin-left: 32.5%;
}

.form-edit-input .k-textbox {
    color: black;
}

.k-textbox.k-state-invalid {
    color: #f31700;
    border-color: #f31700;
}

.form-edit-input .k-textbox-container.k-state-empty>.k-label {
    color: #304b67;
}

.form-edit-input .k-textbox {
    border: 2px solid #fff;
    border-right: 1px solid #ddd;
}

.form-edit-input .k-textbox.k-state-invalid {
    border: 1px solid #f31700;
}

@media screen and (max-width: 992px) {
    .form-edit-container {
        width: 90%;
    }

    .form-edit-confirm-container {
        width: 90%;
    }

    .form-edit-input {
        width: 100%;
    }

    .form-edit-confirm-input {
        width: 100%;
    }

    .form-edit-title {
        margin-left: 5%;
    }
    
    .form-edit-confirm-container .k-button {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

