.k-pager-numbers, .k-state-selected{
    background:#007bff !important;
    color:#fff !important;
}
.k-state-selected:hover{
    color:#007bff !important;
    background-color:transparent !important;
}
.k-pager-numbers:hover{
    color:#007bff !important;
    background-color:transparent !important;
}
.k-grid{
    margin-bottom:2vh;
    min-height:65vh;
    margin-left:1%;
    margin-right:1%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.k-window-titlebar, .k-dialog-titlebar{
    background:#007bff !important;
    color:#fff !important;
}
.k-dialog-buttongroup .k-primary{
    color:#007bff !important;
}
.k-dialog-buttongroup .k-primary:hover{
    background:#007bff !important;
    color:#fff !important;
    border-color:#007bff !important
}

@media screen and (max-width: 992px) {
    .edit-text{
        display:none;
    }
}