.container-cards{
    justify-content: center;
    width:100%;
    display:flex;
    flex-wrap:wrap;
  }
  .flip-card {
  background-color: transparent;
  width: 250px;
  margin: 2%;
  height: 150px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    cursor:pointer;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 3px;
  }
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
  }
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  -webkit-transform: translate3d(0,0,0);
  height: 100%;
  backface-visibility: hidden;
  border-radius:3px;
  }
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
  background-color: #ffffff;
  color: black;
  }
  /* Style the back side */
  .flip-card-back {
    background-color: dodgerblue;
    color: white;
    transform: rotateY(180deg);
  }

  .back-desc{
    font-size:12px;
    padding-left:5px;
    padding-right:5px;
    margin-top:-10px;
  }

  .icon-card{
    width:25%;
    position: absolute;
    left: 50%;
    top: 42%;
    transform: translate(-50%, -50%);
  }
  .section-dash{
    box-shadow:none;
    padding-bottom:10vh;
    background-image: linear-gradient(#f5f5f5, #000000);
    padding-bottom:5vh;
    min-height:69vh;
  }
  .section-logo-background{
    width:25%;
    max-width:475px;
    position:fixed;
    bottom:5vh;
    left:0;
    opacity:0.1;
  }
  body{

    margin:0;
    padding:0;
  }
  .footer-dash{
    height:5vh;
    background-color:#f5f5f5;
    width:100%;
    position:fixed;
    bottom:0;
    border-top:1px solid #ccc;
  }

  .credits{
    font-size:12px;
    margin-top: 12px;
    color:#707070;
    text-align:center;
  }

  @media screen and (max-width: 992px) {
    .section-dash {
      padding-top:2vh;
      box-shadow:none;
      padding-bottom:10vh;
    }
  }
  @media screen and (max-width: 600px) {
    .flip-card{
      width:85%;
    }
    .section-logo-background{
      width:180px;
      position:fixed;
      left: 52%;
      top: 34.9%;
      transform: translate(-50%, -50%);
      opacity:0.5;
      transition:0.2s;
    }
  }

  .front-title{
    width:100%;
    background-color: dodgerblue;
    border-top:1px solid #ccc;
    padding-top:7px;
    padding-bottom:7px;
    text-align:center;
    bottom:0;
    color:#fff;
    position:absolute;
    border-radius:0 0 3px 3px;
  }

  .back-arrow{
    font-size:20px;
    padding-top:10px;
    padding-bottom:10px;
    text-align:center;
    bottom:0;
    color:#fff;
    position:absolute;
    transition:0.8s;
  }

  .back-arrow:hover{
    color:#000;
    transition:0.8s;
  }